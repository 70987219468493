<template>
  <div id="flix-bus">

    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" :title="'FlixBus'">
    </breadcrumb-base>

    <div class="vs-row flex justify-center">
      <div class="vs-col w-full">
        <vx-card class=" p-4">
          <a
            href="https://shop.flixbus.de/rebooking/login?atb_pdid=9d8abe9c-53ff-4ff3-88fd-3ecb330f3ba1&_sp=fa60cc17-7fbd-4ddd-98e7-3605b78beccd.1634809063473&_ga=2.265206735.1372247133.1634809055-2105925932.1601299457"
            target="_blank">
            <vs-button class="mx-2 mb-2" size="normal" color="primary" type="filled">
              Storno Flixbus Ticket
            </vs-button>
          </a>
          <div class="vs-row flex form-holder justify-center">

            <div class="vs-col">
              <label class="vs-select--label">{{ $t('Trip') }}</label>
              <div class="py-2"></div>
              <div class="vs-row">
                <vs-radio v-model="tripType" v-on:change="onTripTypeChanged" :vs-value="0">
                  {{ $t('OneWay') }}
                </vs-radio>
                <vs-radio class="px-4" v-model="tripType" v-on:change="onTripTypeChanged"
                          :vs-value="1">
                  {{ $t('RoundTrip') }}
                </vs-radio>
              </div>
            </div>
            <div class="vs-col px-4">
              <label class="vs-select--label">{{ $t('FromCity') }}</label>
              <div class="w-full">
                <dynamic-cities-drop-down
                  :placeholder="$t('FromCity')"
                  @on-item-selected="selectedFromCity = $event">
                </dynamic-cities-drop-down>
              </div>
            </div>
            <div class="vs-col px-4">
              <label class="vs-select--label">{{ $t('ToCity') }}</label>
              <div class="w-full">
                <dynamic-cities-drop-down
                  :placeholder="$t('ToCity')"
                  @on-item-selected="selectedToCity = $event">
                </dynamic-cities-drop-down>
              </div>
            </div>

            <div class="vs-col px-4">
              <label class="vs-select--label">{{ $t('Departure') }}</label>
              <div></div>
              <date-picker
                class="py-1" :placeholder="$t('Departure')"
                :disabled-date="disablePastDate"
                v-model="departureDate" type="date"></date-picker>
            </div>
            <div class="vs-col px-4" v-if="tripType == 1">
              <label class="vs-select--label">{{ $t('Return') }}</label>
              <div></div>
              <date-picker class="py-1"
                           :disabled-date="disableDeparturePastDate"
                           :placeholder="$t('Return')"
                           v-model="returnDate" type="date"></date-picker>
            </div>
          </div>
          <div class="py-4"></div>
          <vs-row>
            <div class="vs-row sm:w-auto sm:w-full lg:w-1/3 px-2">
              <vx-card class="mb-8 mt-4 mr-5">
                <div class="vx-row vs-align-center text-center">
                  <div class="vs-col item-card-text-holder">
                    <h1 class="mx-3 item-card-text">{{ $t('Adult') }}</h1>
                    <p class="mx-3 item-card-subtitle">{{ $t('For15Years') }}</p>
                  </div>
                  <vs-spacer></vs-spacer>
                  <vs-button @click="removeAdult()" class="mx-2" size="normal" color="danger"
                             type="filled"
                             icon-pack="feather" icon="icon-minus"></vs-button>
                  <!--                 <h4 class="item-card-amount">{{ adultCount }}</h4>-->
                  <div class="flix-input-holder mx-1">
                    <div class="">
                      <input v-model="adultCount"
                             type="text"
                             placeholder="0" class="flix-input-field"
                      >
                    </div>
                  </div>
                  <vs-button @click="addAdult()" class="mx-2" size="normal" color="success"
                             type="filled"
                             icon-pack="feather" icon="icon-plus"></vs-button>
                </div>
              </vx-card>
            </div>
            <div class="vs-row sm:w-auto sm:w-full lg:w-1/3 px-2">
              <vx-card class="mb-8 mt-4 mr-5">
                <div class="vx-row vs-align-center text-center">
                  <div class="vs-col item-card-text-holder">
                    <h1 class="mx-3 item-card-text">{{ $t('Children') }}</h1>
                    <p class="mx-3 item-card-subtitle">{{ $t('ZeroTo14Years') }}</p>
                  </div>
                  <vs-spacer></vs-spacer>
                  <vs-button @click="removeChildren()" class="mx-2" size="normal" color="danger"
                             type="filled"
                             icon-pack="feather" icon="icon-minus"></vs-button>
                  <!--                 <h4 class="item-card-amount">{{ childrenCount }}</h4>-->
                  <div class="flix-input-holder mx-1">
                    <div class="">
                      <input v-model="childrenCount"
                             type="text"
                             placeholder="0" class="flix-input-field"
                      >
                    </div>
                  </div>
                  <vs-button @click="addChildren()" class="mx-2" size="normal" color="success"
                             type="filled"
                             icon-pack="feather" icon="icon-plus"></vs-button>
                </div>
              </vx-card>
            </div>
            <div class="vs-row  sm:w-auto sm:w-full lg:w-1/3 px-2">
              <vx-card class="mb-8 mt-4 mr-5">
                <div class="vx-row vs-align-center text-center">
                  <div class="vs-col item-card-text-holder">
                    <h1 class="mx-3 item-card-text">{{ $t('Bikes') }}</h1>
                    <p class="mx-3 item-card-subtitle">{{ $t('BikeHelp') }}</p>
                  </div>
                  <vs-spacer></vs-spacer>
                  <vs-button @click="removeBike()" class="mx-2" size="normal" color="danger"
                             type="filled"
                             icon-pack="feather" icon="icon-minus"></vs-button>
                  <!--                 <h4 class="item-card-amount">{{ bikeSlotCount }}</h4>-->
                  <div class="flix-input-holder mx-1">
                    <div class="">
                      <input v-model="bikeSlotCount"
                             type="text"
                             placeholder="0" class="flix-input-field"
                      >
                    </div>
                  </div>
                  <vs-button @click="addBike()" class="mx-2" size="normal" color="success"
                             type="filled"
                             icon-pack="feather" icon="icon-plus"></vs-button>
                </div>
              </vx-card>
            </div>
          </vs-row>

          <vs-button style="display: flex;" class="m-auto" @click="searchTrips">{{
              $t('Search')
            }}
          </vs-button>
        </vx-card>
      </div>
    </div>
    <div class="container">

      <vs-tabs v-if="tripsData" alignment="fixed" v-model="activeTabIndex">
        <vs-tab :label="selectedFromCity.city_name + ' → ' + selectedToCity.city_name">
          <div>
            <div v-if="tripsData" class="vs-row flex justify-center">
              <!--    Empty List State -->
              <transition name="fade">
                <div class="h-screen flex w-full bg-img"
                     v-if="tripsData.length === 0 && !errorFetching">
                  <div
                    class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
<!--                    <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
<!--                         class="mx-auto mb-4 w-1/2">-->
                    <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('NoServicesAvailable') }}</h1>
                  </div>
                </div>
              </transition>
              <template v-for="(item, index) in tripsData">
                <vx-card class="mb-3">

                  <div class="w-full">
                    <vs-row>
                      <vs-row class="flex p-3 md:w-2/3">
                        <div class="card-trip-title vs-col">
                          <h3>{{ $t('Departure') }}:
                            <strong>{{ getTime(item.departure.timestamp, item.departure.tz) }}</strong>
                          </h3>
                        </div>
                        <vs-spacer></vs-spacer>
                        <p class="card-trip-duration">{{ item.duration.hour }} h
                          {{ item.duration.minutes }} m</p>
                        <vs-spacer></vs-spacer>
                        <div class="card-trip-title  vs-col">
                          <h3>{{ $t('Arrival') }}:
                            <strong>{{ getTime(item.arrival.timestamp, item.arrival.tz) }}</strong>
                          </h3>
                        </div>
                      </vs-row>
                      <vs-spacer></vs-spacer>
                      <p class="px-3 card-trip-price">
                        <strong>{{ item.price_total_sum | germanNumberFormat }}</strong></p>
                    </vs-row>
                    <vs-row class="flex px-3 md:w-2/3">
                      <div class="vs-col">
                        <h4>{{ item.from.name }}</h4>
                      </div>
                      <vs-spacer></vs-spacer>
                      <div class="vs-col">
                        <h4>{{ item.to.name }}</h4>
                      </div>
                    </vs-row>

                    <vs-row class="flex px-3 pt-5 ">
                      <div class="vs-col">
                        <vx-tooltip v-if="item.transfer_type === 'Transfer'"
                                    :text="getTransferText(item)" position="right">
                          <vs-row>
                            <h5 class="pr-2">{{ item.interconnection_transfers.length }}
                              {{ item.transfer_type }}</h5>
                            <feather-icon icon="InfoIcon" svgClasses="h-6 w-6 stroke-current"
                                          class="block"/>
                          </vs-row>
                        </vx-tooltip>
                        <h5 v-else>{{ item.transfer_type }}</h5>
                      </div>
                      <vs-spacer></vs-spacer>
                      <div class=" vs-col" v-if="item.status == 'available'">
                        <vs-button
                          v-if="selectedTripItem != null && selectedTripItem.uid == item.uid"
                          @click="removeTrip(item)" color="danger">{{ $t('Remove') }}
                          {{ adultCount + childrenCount }} {{ $t('Seat') }}
                        </vs-button>
                        <vs-button v-else @click="reserveTrip(item)">{{ $t('Reserve') }}
                          {{ searchedAdultCount + searchedChildrenCount }} {{ $t('Seat') }}
                        </vs-button>
                      </div>
                      <div class=" vs-col" v-if="item.status == 'full'">
                        <p class="text-danger">{{ $t('tripFullyBooked') }}</p>
                      </div>
                    </vs-row>
                  </div>
                </vx-card>
              </template>
            </div>
          </div>
        </vs-tab>
        <vs-tab v-if="tripType == 1"
                :label="selectedToCity.city_name + ' → ' + selectedFromCity.city_name">
          <div>
            <div v-if="returnTripsData" class="vs-row flex justify-center">
              <!--    Empty List State -->
              <transition name="fade">
                <div class="h-screen flex w-full bg-img"
                     v-if="returnTripsData.length === 0 && !errorFetching">
                  <div
                    class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
<!--                    <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
<!--                         class="mx-auto mb-4 w-1/2">-->
                    <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('NoServicesAvailable') }}</h1>
                  </div>
                </div>
              </transition>
              <template v-for="(item, index) in returnTripsData">
                <vx-card class="mb-3">

                  <div class="w-full">
                    <vs-row>
                      <vs-row class="flex p-3 md:w-2/3">
                        <div class="card-trip-title vs-col">
                          <h3>{{ $t('Departure') }}:
                            <strong>{{ getTime(item.departure.timestamp, item.departure.tz) }}</strong>
                          </h3>
                        </div>
                        <vs-spacer></vs-spacer>
                        <p class="card-trip-duration">{{ item.duration.hour }} h
                          {{ item.duration.minutes }} m</p>
                        <vs-spacer></vs-spacer>
                        <div class="card-trip-title  vs-col">
                          <h3>{{ $t('Arrival') }}:
                            <strong>{{ getTime(item.arrival.timestamp, item.arrival.tz) }}</strong>
                          </h3>
                        </div>
                      </vs-row>
                      <vs-spacer></vs-spacer>
                      <p class="px-3 card-trip-price">
                        <strong>{{ item.price_total_sum | germanNumberFormat }}</strong></p>
                    </vs-row>
                    <vs-row class="flex px-3 md:w-2/3">
                      <div class="vs-col">
                        <h4>{{ item.from.name }}</h4>
                      </div>
                      <vs-spacer></vs-spacer>
                      <div class="vs-col">
                        <h4>{{ item.to.name }}</h4>
                      </div>
                    </vs-row>

                    <vs-row class="flex px-3 pt-5 ">
                      <div class="vs-col">
                        <vx-tooltip v-if="item.transfer_type === 'Transfer'"
                                    :text="getTransferText(item)" position="right">
                          <vs-row><h5 class="pr-2">{{ item.interconnection_transfers.length }}
                            {{ item.transfer_type }}</h5>
                            <feather-icon icon="InfoIcon" svgClasses="h-6 w-6 stroke-current"
                                          class="block"/>
                          </vs-row>
                        </vx-tooltip>
                        <h5 v-else>{{ item.transfer_type }}</h5>
                      </div>
                      <vs-spacer></vs-spacer>

                      <div class=" vs-col" v-if="item.status == 'available'">
                        <vs-button
                          v-if="selectedReturnTripItem != null && selectedReturnTripItem.uid == item.uid"
                          @click="removeTrip(item)" color="danger">{{ $t('Remove') }}
                          {{ adultCount + childrenCount }} {{ $t('Seat') }}
                        </vs-button>
                        <vs-button v-else @click="reserveTrip(item, true)">{{ $t('Reserve') }}
                          {{ searchedAdultCount + searchedChildrenCount }} {{ $t('Seat') }}
                        </vs-button>
                      </div>
                      <div class=" vs-col" v-if="item.status == 'full'">
                        <p class="text-danger">{{ $t('tripFullyBooked') }}</p>
                      </div>
                    </vs-row>
                  </div>
                </vx-card>
              </template>
            </div>
          </div>
        </vs-tab>
      </vs-tabs>

    </div>
    <vx-modal v-if="reserveTripData && passengersData"
              style="padding: 20px" position-right parent="body"
              default-index="1"
              color="primary" class="" spacer :click-not-close="true">
      <template slot="body">
        <div class="mt-6 flex items-center justify-between px-6">
          <h4></h4>
          <feather-icon @click="activateSideBar(false)" icon="XIcon"
                        class="cursor-pointer"></feather-icon>
        </div>
        <vs-row class="w-full">
          <div class="md:w-3/4  px-4">
            <vx-card class="my-3 p-3">
              <h2><strong>{{ $t('PassengerDetails') }}</strong></h2>
              <div class="mt-4" v-bind:key="index"
                   v-for="(passenger, index) in passengersData.response.trips[0].passengers">
                <h3><strong>{{ index + 1 }}. {{ passenger.type | title }}</strong></h3>
                <vs-row class="py-4 w-full">
                  <div class="w-1/2 pr-4">
                    <vs-input
                      v-validate="'required'"
                      validate-on="change"
                      :name="$t('FirstName')"
                      class="w-full" :label-placeholder="$t('FirstName')"
                      v-model="typedPassengerData['firstname' + index]"/>
                    <span class="text-danger text-sm">{{ errors.first($t('FirstName')) }}</span>
                  </div>
                  <div class="pl-4 w-1/2">
                    <vs-input
                      v-validate="'required'"
                      validate-on="change"
                      :name="$t('LastName')"
                      class="w-full" :label-placeholder="$t('LastName')"
                      v-model="typedPassengerData['lastname' + index]"/>
                    <span class="text-danger text-sm">{{ errors.first($t('LastName')) }}</span>
                  </div>
                </vs-row>
                <vs-row class="py-4 w-full" v-if="passenger.type == 'children'">
                  <div class="w-1/2 pr-4">
                    <div class="vs-col px-2">
                      <label class="vs-select--label">{{ $t('DOB') }}</label>
                      <div></div>

                      <!--                        :disabled-date="childDisableDate"-->
                      <date-picker
                        :default-value="new Date()"
                        class="py-1" :placeholder="$t('DOB')"
                        v-model="typedPassengerData['dob' + index]"
                        type="date"></date-picker>
                    </div>
                  </div>
                </vs-row>
              </div>
            </vx-card>
            <vx-card class="my-3 p-3">
              <h2><strong>{{ $t('ContactDetails') }}</strong></h2>
              <div class="mt-4">
                <vs-row class="py-4 w-full">
                  <div class="w-1/2 pr-4">
                    <vs-input
                      v-validate="'required|email'"
                      validate-on="change"
                      :name="$t('Email')"
                      class="w-full" :label-placeholder="$t('Email')"
                      v-model="typedPassengerData['email']"/>
                    <span class="text-danger text-sm">{{ errors.first($t('Email')) }}</span>
                  </div>

                  <div class="w-1/2 pl-4">
                    <vs-input
                      v-validate="'required'"
                      validate-on="change"
                      :name="$t('Mobile')"
                      :label="$t('Mobile')"
                      class="w-full" placeholder="+491234567890"
                      v-model="typedPassengerData['mobile']"/>
                    <span class="text-danger text-sm">{{ errors.first($t('Mobile')) }}</span>
                  </div>

                </vs-row>
              </div>
            </vx-card>
          </div>
          <div class="md:w-1/4 px-4 py-3">
            <h2><strong>{{ $t('Your Order') }}</strong></h2>
            <div class="py-2"></div>
            <div>
              <div class="flix-cart-section"
                   v-for="(t, index) in Object.keys(reserveTripData.cart.items)">
                <table class="flix-connection">
                  <h3><strong>{{
                      getDate(reserveTripData.cart.items[t].departure.timestamp, reserveTripData.cart.items[t].departure.tz)
                    }}, {{
                      getTime(reserveTripData.cart.items[t].departure.timestamp, reserveTripData.cart.items[t].departure.tz)
                    }}</strong> - {{
                      getTime(reserveTripData.cart.items[t].arrival.timestamp, reserveTripData.cart.items[t].arrival.tz)
                    }}</h3>
                  <div class="py-2"></div>
                  <tbody>
                  <tr>
                    <td>
                      <div class="flix-connection__station">
                        {{ reserveTripData.cart.items[t].from.name }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="flix-connection__station">{{
                          reserveTripData.cart.items[t].to.name
                        }}
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div class="flix-cart-items-container-with-price">
                  <div class="flix-cart-item">
                    <span> {{ reserveTripData.cart.items[t].products_description }} </span>
                  </div>
                  <div class="flix-cart-item-price"><span class="flix-price">{{
                      reserveTripData.cart.items[t].price.total | germanNumberFormat
                    }}</span></div>
                </div>
              </div>
              <div v-if="reserveTripData.cart.price.discount" class="flix-cart-total"
                   style="font-size: 14px;">
                <div><b>{{ $t('Discount') }}</b></div>
                <div>
                  <b><span class="text-danger flix-price">{{
                      reserveTripData.cart.price.discount | germanNumberFormat
                    }}</span></b>
                </div>
              </div>
              <div class="flix-cart-total">
                <div><b>{{ $t('Total') }}</b> {{ $t('inclVAT') }}</div>
                <div>
                  <b><span class="flix-price">{{
                      reserveTripData.cart.price.subtotal | germanNumberFormat
                    }}</span></b>
                </div>
              </div>
              <div class="py-4">
                <div>
                  <vs-col>
                    <h4 class="text-warning">{{ $t('EnterVoucher') }}</h4>
                    <div class="py-2 flex">
                      <vs-input
                        class="w-full" :placeholder="$t('Voucher')"
                        :disabled="voucherAdded"
                        v-model="typedVoucher"/>
                      <vs-spacer></vs-spacer>
                      <vs-button
                        v-if="!voucherAdded"
                        @click="addVoucher"
                        :disabled="voucherAdded"
                        class="ml-4" color="primary" type="filled" icon-pack="feather"
                        icon="icon-chevron-right"></vs-button>
                      <vs-button
                        v-else
                        @click="removeVoucher"
                        :disabled="!voucherAdded"
                        class="ml-4" color="danger" type="filled" icon-pack="feather"
                        icon="icon-trash-2"></vs-button>
                    </div>

                  </vs-col>
                </div>
              </div>

              <vs-button class="w-full mt-4" @click="proceedToPayment">
                {{ $t('ProceedToPayment') }}
              </vs-button>

            </div>
          </div>
        </vs-row>
      </template>
    </vx-modal>

    <!--Recipt-->
    <vx-modal v-if="showInvoice" component-class="modal-container-fit">
      <template slot="body">
        <div v-if="invoiceData">
          <receipt-print
            v-on:close="closeModal()"
            ref="printCard"
            :print-report-type="5" :purchase-data="invoiceData"></receipt-print>
        </div>
      </template>
    </vx-modal>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import VxModal from '@/layouts/components/custom/VxModal'
import moment from 'moment'
import DynamicCitiesDropDown from '@/views/pages/sellers/flixbus/DynamicCitiesDropDown'
import ReceiptPrint from '@/views/components/ReceiptPrint'

export default {
  name: 'FlixBus',
  components: {
    ReceiptPrint,
    DatePicker,
    VxModal,
    DynamicCitiesDropDown,
  },
  data() {
    return {
      breadcrumb: [
        {title: 'Home', i18n: 'Home', url: '/'},
        {title: 'FlixBus', i18n: 'FlixBus', active: true},
      ],
      typedVoucher: '',
      voucherAdded: false,
      tripsData: null,
      returnTripsData: null,
      selectedFromCity: null,
      selectedToCity: null,
      departureDate: null,
      returnDate: null,
      tripType: 0, // 0 - oneway, 1 - round Trip
      activeTabIndex: 0,
      adultCount: 1,
      childrenCount: 0,
      bikeSlotCount: 0,
      searchedAdultCount: 1,
      searchedChildrenCount: 0,
      searchedBikeSlotCount: 0,
      reserveTripData: null,
      passengersData: null,
      selectedTripItem: null,
      selectedReturnTripItem: null,
      typedPassengerData: {},
      invoiceData: null,
      showInvoice: false,
      finalPrice: null,
      reservationId: null,
    }
  },
  methods: {
    disablePastDate(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)

      return date < today
    },
    disableDeparturePastDate(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)

      return date < (this.departureDate || today)
    },
    childDisableDate(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)

      return date > today || date < new Date(today.getTime() - 0 * 365 * 24 * 3600 * 1000)
    },
    closeModal() {
      this.finalPrice = null
      this.invoiceData = null
      this.showInvoice = false
    },
    showInvoiceModal() {
      this.reserveTripData = null
      this.passengersData = null
      this.selectedTripItem = null
      this.showInvoice = true
      this.checkPrintCardPop()
    },
    activateSideBar(status) {
      this.showSideBar = status
      if (!status) {
        this.reserveTripData = null
        this.passengersData = null
        this.selectedTripItem = null
        this.selectedReturnTripItem = null
        this.typedPassengerData = {}
        this.typedVoucher = ''
        this.voucherAdded = false
      }
    },
    getTransferText(item) {
      let s = ''
      item.interconnection_transfers.forEach((transfer) => {
        s += `${transfer.station_name}, \n`
        s += 'Transfer time: ' + `${transfer.duration.hour} hrs ${transfer.duration.minutes} mins`
      })
      return s
    },
    getDate(milli, tz) {
      const d = new Date(milli * 1000)
      let t = tz.replace('GMT', '')
      const op = t[0]
      t = t.replace(op, '')
      const s = t.split(':')
      let m = moment(d.toUTCString()).add(Number(s[0]), 'hours')
      m = m.add(Number(s[1]), 'minutes')

      const date = m.toDate()
      return date.toLocaleDateString('de')
      const dateTimeFormat = new Intl.DateTimeFormat('de', options1)
      return dateTimeFormat.format(date)
    },
    getTime(milli, tz) {
      const d = new Date(milli * 1000)
      let t = tz.replace('GMT', '')
      const op = t[0]
      t = t.replace(op, '')
      const s = t.split(':')
      let m = moment(d.toUTCString()).add(Number(s[0]), 'hours')
      m = m.add(Number(s[1]), 'minutes')
      const msplit = (m.toISOString()).split('T')[1].split(':')
      return `${msplit[0]}:${msplit[1]}`
    },
    getFullTime(milli, tz) {
      const d = new Date(milli * 1000)
      let t = tz.replace('GMT', '')
      const op = t[0]
      t = t.replace(op, '')
      const s = t.split(':')
      let m = moment(d.toUTCString()).add(Number(s[0]), 'hours')
      m = m.add(Number(s[1]), 'minutes')
      return m.toISOString()
    },
    removeAdult() {
      if (this.adultCount > 0) {
        this.adultCount -= 1
      }
    },
    addAdult() {
      this.adultCount += 1
    },
    removeChildren() {
      if (this.childrenCount > 0) {
        this.childrenCount -= 1
      }
    },
    addChildren() {
      this.childrenCount += 1
    },
    removeBike() {
      if (this.bikeSlotCount > 0) {
        this.bikeSlotCount -= 1
      }
    },
    addBike() {
      this.bikeSlotCount += 1
    },
    onTripTypeChanged() {

    },
    fetchNewCities() {

    },
    searchTrips() {
      this.searchedAdultCount = this.adultCount
      this.searchedChildrenCount = this.childrenCount
      this.searchedBikeSlotCount = this.bikeSlotCount
      if (!this.selectedFromCity) {
        this.showErrorToast('Please select from city')
        return
      }
      if (!this.selectedToCity) {
        this.showErrorToast('Please select to city')
        return
      }
      if (!this.departureDate) {
        this.showErrorToast('Please select to departure date')
        return
      }
      if (this.adultCount == 0 && this.childrenCount == 0 && this.bikeSlotCount == 0) {
        this.showErrorToast('Please add passenger')
        return
      }
      // const options1 = { year: 'numeric', month: 'numeric', day: 'numeric' }
      // const dateTimeFormat = new Intl.DateTimeFormat('de', options1);
      const depature = moment(this.departureDate).format('DD.MM.YYYY')
      const data = {
        configuration: 2,
        from_id: this.selectedFromCity.city_id,
        to_id: this.selectedToCity.city_id,
        departure_date: depature,
        adult: this.adultCount,
        children: this.childrenCount,
        bikes: this.bikeSlotCount,
      }

      this.$vs.loading()
      this.$store.dispatch('flixBus/triggerFlixBusEndpoint', {payload: data})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          if (data.message_type == 1) {
            const temp_res = data.response
            const trips = []
            temp_res.trips.forEach((trip) => {
              trip.items.forEach((item) => {
                let t = {}
                t.from = trip.from
                t.to = trip.to
                t = {...t, ...item}
                trips.push(t)
              })
            })
            trips.sort((a, b) => {
              const keyA = a.departure.timestamp
              const keyB = b.departure.timestamp
              // Compare the 2 dates
              if (keyA < keyB) return -1
              if (keyA > keyB) return 1
              return 0
            })
            this.tripsData = trips
            console.log(this.tripsData)
          } else {
            this.showErrorToast(data.message)
          }
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.errorFetching = true
          let msg = ''
          try {
            msg = error.response.data.message
          } catch (err) {
            msg = ''
          }
          this.showErrorToast(msg)
        })
      if (this.tripType == 1) {
        this.searchReturnTrips()
      }
    },
    searchReturnTrips() {
      if (!this.selectedFromCity) {
        this.showErrorToast('Please select from city')
        return
      }
      if (!this.selectedToCity) {
        this.showErrorToast('Please select to city')
        return
      }
      if (!this.returnDate) {
        this.showErrorToast('Please select to return date')
        return
      }
      if (this.adultCount == 0 && this.childrenCount == 0 && this.bikeSlotCount == 0) {
        this.showErrorToast('Please add passenger')
        return
      }
      // const options1 = { year: 'numeric', month: 'numeric', day: 'numeric' }
      // const dateTimeFormat = new Intl.DateTimeFormat('de', options1);
      const returnD = moment(this.returnDate).format('DD.MM.YYYY')
      const data = {
        configuration: 2,
        from_id: this.selectedToCity.city_id,
        to_id: this.selectedFromCity.city_id,
        departure_date: returnD,
        adult: this.adultCount,
        children: this.childrenCount,
        bikes: this.bikeSlotCount,
      }

      this.$vs.loading()
      this.$store.dispatch('flixBus/triggerFlixBusEndpoint', {payload: data})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          if (data.message_type == 1) {
            const temp_res = data.response
            const trips = []
            temp_res.trips.forEach((trip) => {
              trip.items.forEach((item) => {
                let t = {}
                t.from = trip.from
                t.to = trip.to
                t = {...t, ...item}
                trips.push(t)
              })
            })
            trips.sort((a, b) => {
              const keyA = a.departure.timestamp
              const keyB = b.departure.timestamp
              // Compare the 2 dates
              if (keyA < keyB) return -1
              if (keyA > keyB) return 1
              return 0
            })
            this.returnTripsData = trips
          } else {
            this.showErrorToast(data.message)
          }
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.errorFetching = true
          let msg = ''
          try {
            msg = error.response.data.message
          } catch (err) {
            msg = ''
          }
          this.showErrorToast(msg)
        })
    },
    showErrorToast(msg) {
      this.$vs.notify({
        position: 'top-center',
        title: 'Error',
        text: msg,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger',
      })
    },
    removeTrip() {
      const data = {
        configuration: 16,
        trip_uid: this.selectedTripItem.uid,
        reservation_id: this.reservationId,
      }

      this.$vs.loading()
      this.$store.dispatch('flixBus/triggerFlixBusEndpoint', {payload: data})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          if (data.message_type == 1) {
            if (this.activeTabIndex == 1) this.selectedReturnTripItem = null
            else this.selectedTripItem = null

            this.reserveTripData.cart = data.response.cart
          } else {
            this.showErrorToast(data.message)
          }
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.errorFetching = true
          let msg = ''
          try {
            msg = error.response.data.response.message
          } catch (err) {
            msg = ''
          }
          this.showErrorToast(msg)
        })
    },
    fetchReservationDetails(isPlain = false) {
      const data = {
        configuration: 7,
        reservation_id: this.reservationId,
      }

      this.$vs.loading()
      this.$store.dispatch('flixBus/triggerFlixBusEndpoint', {payload: data})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          if (data.message_type == 1) {
            this.reserveTripData = data.response
            if (!isPlain) {
              if (this.tripType == 1 && this.activeTabIndex != 1) {
                this.activeTabIndex = 1
              }
              if (this.tripType == 0 && this.selectedTripItem) {
                this.fetchPassengerDetails()
              }
              if (this.tripType == 1 && this.selectedTripItem && this.selectedReturnTripItem) {
                this.fetchPassengerDetails()
              }
            }
          } else {
            this.showErrorToast(data.message)
          }
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.errorFetching = true
          let msg = ''
          try {
            msg = error.response.data.response.message
          } catch (err) {
            msg = ''
          }
          this.showErrorToast(msg)
        })
    },
    addVoucher() {
      const data = {
        configuration: 17,
        voucher: this.typedVoucher,
        reservation_id: this.reservationId,
      }

      this.$vs.loading()
      this.$store.dispatch('flixBus/triggerFlixBusEndpoint', {payload: data})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          if (data.message_type == 1) {
            this.fetchReservationDetails()
            this.voucherAdded = true
          } else {
            this.showErrorToast(data.message)
          }
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.errorFetching = true
          let msg = ''
          try {
            msg = error.response.data.response.message
          } catch (err) {
            msg = ''
          }
          this.showErrorToast(msg)
        })
    },
    removeVoucher() {
      const data = {
        configuration: 17,
        voucher: this.typedVoucher,
        voucher_type: 1,
        reservation_id: this.reservationId,
      }

      this.$vs.loading()
      this.$store.dispatch('flixBus/triggerFlixBusEndpoint', {payload: data})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          if (data.message_type == 1) {
            this.voucherAdded = false
            this.fetchReservationDetails()
          } else {
            this.showErrorToast(data.message)
          }
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.errorFetching = true
          let msg = ''
          try {
            msg = error.response.data.response.message
          } catch (err) {
            msg = ''
          }
          this.showErrorToast(msg)
        })
    },
    reserveTrip(item, isReturn = false) {
      if (isReturn) this.selectedReturnTripItem = item
      else this.selectedTripItem = item
      const data = {
        configuration: 6,
        trip_uid: isReturn ? this.selectedReturnTripItem.uid : this.selectedTripItem.uid,
        adult: this.adultCount,
        children: this.childrenCount,
        bikes: this.bikeSlotCount,
      }
      if (isReturn && this.reservationId) data.reservation_id = this.reservationId

      this.$vs.loading()
      this.$store.dispatch('flixBus/triggerFlixBusEndpoint', {payload: data})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          if (data.message_type == 1) {
            this.reserveTripData = data
            this.reservationId = data.reservation_id
            this.fetchReservationDetails()
          } else {
            this.showErrorToast(data.response.message)
            if (isReturn) this.selectedReturnTripItem = null
            else this.selectedTripItem = null
          }
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.errorFetching = true
          let msg = ''
          try {
            msg = error.response.data.response.message
          } catch (err) {
            msg = ''
          }
          this.showErrorToast(msg)
        })
    },
    fetchPassengerDetails() {
      const data = {
        configuration: 8,
        reservation_id: this.reservationId,
        reservation_token: this.reserveTripData.cart.reservation.token,
      }

      this.$vs.loading()
      this.$store.dispatch('flixBus/triggerFlixBusEndpoint', {payload: data})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          if (data.message_type == 1) {
            this.passengersData = data
          } else {
            this.showErrorToast(data.message)
          }
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.errorFetching = true
          let msg = ''
          try {
            msg = error.response.data.response.message
          } catch (err) {
            msg = ''
          }
          this.showErrorToast(msg)
        })
    },
    fetchCities() {

    },
    proceedToPayment() {
      if (this.errors.items.length > 0) {
        this.showErrorToast('Invalid Data, Please correct all fields.')
        return
      }
      if (this.typedPassengerData['mobile'].indexOf("+")) {
        this.showErrorToast('Fill mobile number with country code(+xx)')
        return
      }
      this.$vs.loading()
      this.$validator.validateAll().then((result) => {
        if (result) {
          // if form have no errors
          const d = {
            'with_donation': false,
            'donation_partner': 'atmosfair',
            'reservation_token': this.reserveTripData.cart.reservation.token,
          }
          let passCount = 0
          let error = false;
          this.passengersData.response.trips.forEach((trip, tripIndex) => {
            trip.passengers.forEach((p, index) => {
              let dob = ''

              if (p.type == 'children') {
                if (!this.typedPassengerData[`dob${index}`]) {
                  this.showErrorToast('Please select Date of Birth')
                  this.$vs.loading.close()
                  error = true;
                  return
                }
              }
              dob = moment(this.typedPassengerData[`dob${index}`]).format('DD.MM.YYYY')

              d[`passengers[${passCount}][firstname]`] = this.typedPassengerData[`firstname${index}`]
              d[`passengers[${passCount}][lastname]`] = this.typedPassengerData[`lastname${index}`]
              d[`passengers[${passCount}][phone]`] = this.typedPassengerData['mobile']
              if (p.type == 'children') {
                d[`passengers[${passCount}][birthdate]`] = dob
              }
              d[`passengers[${passCount}][patronymic]`] = ''
              d[`passengers[${passCount}][parental_permission]`] = false
              d[`passengers[${passCount}][gender]`] = 'male'
              d[`passengers[${passCount}][citizenship]`] = ''
              d[`passengers[${passCount}][identification_type]`] = ''
              d[`passengers[${passCount}][identification_number]`] = ''
              d[`passengers[${passCount}][product_type]`] = p.product_type
              d[`passengers[${passCount}][type]`] = p.type
              d[`passengers[${passCount}][passenger_no]`] = p.passenger_no
              d[`passengers[${passCount}][ticket_id]`] = p.ticket_id
              d[`passengers[${passCount}][reference_id]`] = p.reference_id
              d[`passengers[${passCount}][trip_type]`] = p.trip_type

              passCount += 1
            })
          })

          if (error) {
            return;
          }
          // TODO: Add Add Passenger to Resevation

          const passengerFinalData = {
            configuration: 9,
            reservation_id: this.reservationId,
            form_data: JSON.stringify(d),
            mobile: this.typedPassengerData['mobile'],
            email: this.typedPassengerData['email'],
          }

          this.$vs.loading()
          this.$store.dispatch('flixBus/triggerFlixBusEndpoint', {payload: passengerFinalData})
            .then((data) => {
              this.$vs.loading.close()
              this.errorFetching = false
              if (data.message_type == 1) {
                this.finalPrice = data.response.cart.price.subtotal
                this.startPayment()
              } else {
                this.showErrorToast(data.message)
              }
            })
            .catch((error) => {
              console.error(error)
              this.$vs.loading.close()
              this.errorFetching = true
              try {
                let errors = [];
                let mss = error.response.data.response.message
              } catch (e) {

              }
              let msg = ''
              try {
                msg = error.response.data.response.message
              } catch (err) {
                msg = ''
              }
              this.showErrorToast(msg)
            })
        } else {
          // form have errors

          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: 'Fill all Details',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        }
      })
    },
    startPayment() {
      // TODO: send it to Start Payment
      const finalData = {
        configuration: 11,
        reservation_id: this.reservationId,
        reservation_token: this.reserveTripData.cart.reservation.token,
        email: this.typedPassengerData.email,
        phone_no: this.typedPassengerData.mobile,
        payment_psp: 'offline',
        payment_method: 'cash',
      }

      this.$vs.loading()
      this.$store.dispatch('flixBus/triggerFlixBusEndpoint', {payload: finalData})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          if (data.message_type == 1) {
            this.processPayment(data)
          } else {
            this.showErrorToast(data.message)
          }
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.errorFetching = true
          let msg = ''
          try {
            msg = error.response.data.response.message
          } catch (err) {
            msg = ''
          }
          this.showErrorToast(msg)
        })
    },
    processPayment(startPaymentResponse) {
      const processPayment = {
        configuration: 12,
        reservation_id: this.reservationId,
        payment_id: startPaymentResponse.payment_id.payment_id,
      }
      this.$vs.loading()
      this.$store.dispatch('flixBus/triggerFlixBusEndpoint', {payload: processPayment})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          if (data.message_type == 1) {
            this.fetchInvoice(data)
          } else if (data.message) {
            this.showErrorToast(data.message)
          } else {
            this.showErrorToast(data.response.message)
          }
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.errorFetching = true
          let msg = ''
          try {
            msg = error.response.data.message
          } catch (err) {
            msg = ''
          }
          this.showErrorToast(msg)
        })
    },
    fetchInvoice(transactionData) {
      // this.finalPrice = transactionData.response.reservation_data.cart.price.subtotal;

      const transactionFinalData = {
        configuration: 13,
        transaction_id: transactionData.transaction_id,
      }
      this.$vs.loading()
      this.$store.dispatch('flixBus/triggerFlixBusEndpoint', {payload: transactionFinalData})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          if (data.message_type == 1) {
            this.invoiceData = data.json_data;
            this.showInvoiceModal()
          } else {
            this.showErrorToast(data.message)
          }
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.errorFetching = true
          let msg = ''
          try {
            msg = error.response.data.message
          } catch (err) {
            msg = ''
          }
          this.showErrorToast(msg)
        })
    },

    checkPrintCardPop() {
      const showPrintPop = localStorage.getItem('show_print_pop') || 'false'
      if (showPrintPop != 'true') {
        setTimeout(() => {
          this.$refs.printCard.printCard()
        }, 1000)
      }
    },
  },
}
</script>

<style lang="scss">
#flix-bus {
  button.vs-tabs--btn {
    font-size: 20px !important;
    font-weight: bold;
  }

  .item-card-text-holder {
    text-align: start;
  }

  .item-card-text {
    font-size: 18px;
    font-weight: bolder;
  }

  .item-card-subtitle {
    font-size: 12px;
  }

  .item-card-amount {
    font-size: 32px;
    padding: 0 8px;
  }

  .card-trip-price {
    color: black;
    font-size: 24px;
  }

  .card-trip-duration {
    font-size: 14px;
  }

  .form-holder {
    align-items: flex-end;
  }

  .vs-radio--label {
    font-size: 18px;
  }

  .vs-select--label {
    font-size: 16px;
  }

  input.mx-input {
    padding: 18px !important;
  }

  .flix-connection {
    width: 100%;
    margin-bottom: 24px;
    color: #444;
    border-collapse: collapse;
  }

  .flix-connection__station {
    position: relative;
    margin-left: calc(24px + 6px);
    padding: 2px;
  }

  .flix-connection__station::after {
    display: block;
    position: absolute;
    top: calc(-1 * 1.375em / 2);
    bottom: calc(-1 * 1.375em / 2);
    left: calc(-1 * 24px - 1px);
    width: 1px;
    background: #8b8b8b;
    content: "";
    z-index: 1;
  }

  .flix-connection__station-info::after {
    top: 0;
  }

  .flix-connection__station-info {
    padding: 2px 4px;
    background: #f7f7f7;
  }

  .flix-connection__station-toggler a {
    font-size: 16px;
  }

  tr:last-child .flix-connection__station-info::after {
    display: none;
  }

  .flix-connection__station::before {
    position: absolute;
    top: calc(1.375em / 2);
    left: calc(-1 * 24px);
    width: 7px;
    height: 1px;
    background: #8b8b8b;
    content: "";
    vertical-align: middle;
    z-index: 2;
  }

  .flix-connection__title {
    margin-bottom: calc(12px / 2);
    font-weight: 700;
    text-align: left;
  }

  .flix-connection__title-arrival {
    font-weight: 300;
  }

  .flix-connection__day {
    font-size: 10px;
    font-weight: 300;
    line-height: 1;
    vertical-align: text-top;
  }

  .flix-connection__time .flix-connection__day {
    position: absolute;
  }

  .flix-connection__time {
    padding-right: calc(24px - 7px);
    white-space: nowrap;
  }

  .flix-connection__time--warning {
    color: #ffad00;
  }

  .flix-connection__time--old {
    color: #8b8b8b;
    text-decoration: line-through;
  }

  .flix-connection__time--danger {
    color: #ff5700;
  }

  .flix-connection__ellipsis {
    width: 0;
    min-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .flix-connection tr:first-child .flix-connection__station::before,
  .flix-connection tr:last-child .flix-connection__station::before {
    display: inline-block;
    top: 12px;
    left: calc(-1 * 24px - 5px);
    width: 9px;
    height: 9px;
    margin-top: calc(-1 * 5px);
    border: 1px solid #8b8b8b;
    border-radius: 50%;
    background: #8b8b8b;
    content: "";
  }

  .flix-connection tr:first-child .flix-connection__station::before {
    background: #fff;
  }

  .flix-connection tr:first-child .flix-connection__station::after {
    top: 12px;
  }

  .flix-connection tr:last-child .flix-connection__station::after {
    bottom: calc(100% - 12px);
  }

  .flix-connection tr td:last-child {
    width: 100%;
  }

  .flix-connection td {
    min-width: 1px;
    vertical-align: top;
  }

  .flix-cart-items-container-with-price {
    display: flex;
    justify-content: space-between;
  }

  .flix-cart-item-price {
    font-size: 18px;
    font-weight: bold;
  }

  .flix-cart-total {
    display: flex;
    justify-content: space-between;
    font-size: 22px;
    padding-top: 12px;
  }

  .flix-cart-section {
    padding: 12px 0;
    border-bottom: 1px solid #e1e1e1;
  }

  /*
  Generic Styling, for Desktops/Laptops
  */
  table {
    width: 98%;
    border-collapse: collapse;
  }

  th {
    background: #333;
    color: white;
    font-weight: bold;
  }

  td, th {
    padding: 6px;
    border: 0px solid #ccc;
    text-align: left;
  }

  .mx-datepicker-main.mx-datepicker-popup {
    z-index: 400000;
  }
}

.mx-datepicker-main.mx-datepicker-popup {
  z-index: 40000;
}


.flix-input-holder {
  width: 48px;
  padding: 0;
  margin: 0;
}

.flix-input-field {
  height: 40px;
  border: 1px solid #c8c8c8;
  text-align: center;
  width: 100%;
  text-indent: 0;
  border-radius: 5px;
  font-size: 24px;
}

</style>
